import { Environment } from '../types/types';

export function getEnvironment(): Environment {
  if (typeof window === "undefined") {
    return Environment.Local;
  }

  switch (window.location.hostname) {
    case "hse-corporate.core.hse24-development.cloud":
      return Environment.Development;
    case "hse-corporate.core.hse24-staging.cloud":
      return Environment.Development;
    case "corporate.hse.com":
      return Environment.Production;
  }

  return Environment.Local;
}

export function env(env: string| undefined){
  if(env == undefined) {
    throw Error(`Unknown environment: ${env}`);
  }

  const value = process.env[env]

  if(value == undefined) {
    throw Error(`Unknown environment variable: ${env}`);
  }

  return value
}
