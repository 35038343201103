export enum Environment {
  Local = "local",
  Development = "development",
  Staging = "staging",
  Production = "production"
}

export enum LocalStorageKeys {
  IdToken = 'IdToken',
  RefreshToken = 'RefreshToken',
  Language = 'Language',
  PathBeforeRedirect = 'PathBeforeRedirect',
  Country = 'Country'
}

export interface TokenResponse {
  accessToken: string;
  expiresIn: number;
  idToken: string;
  tokenType: string;
}

export interface AuthorizationCodeResponse extends TokenResponse {
  refreshToken: string;
}
