import { PageProps } from 'gatsby';
import queryString from 'query-string';
import React, { useEffect } from 'react';
import { fetchOauthTokens, getIdToken, isAuthenticated } from '../../cognito';
import { login } from '../../cognito/redirect';
import Config from '../../config';
import { LocalStorageKeys } from '../../types/types';
import Store from '../../utils/store';

const OAuthSignIn = ({ location }: PageProps) => {

  useEffect(() => {
    const authorizationCode = queryString.parse(location.search).code as string | undefined;

    if (!isAuthenticated() && !authorizationCode) {
      login();
    } else if (authorizationCode) {
      fetchOauthTokens(authorizationCode).then(() => {
        window.location.href = `${Config.baseUrl}/investors/`;
      });
    }
  }, []); // eslint-disable-line

  if (getIdToken()) {
    const route = Store.getItem(LocalStorageKeys.PathBeforeRedirect) || '/investors/';
    Store.removeItem(LocalStorageKeys.PathBeforeRedirect);

    window.location.href = route;
    return;
  }

  return null;
};

export default OAuthSignIn;
