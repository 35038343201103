import { getEnvironment } from '../utils/environment';
import { Environment } from '../types/types';

interface Configuration {
  env: Environment;
  baseUrl: string;
  cognito: {
    url: string;
    clientId: string;
  },
}

const localConfiguration: Configuration = {
  env: Environment.Local,
  baseUrl: "http://localhost:3000",
  cognito: {
    url: "https://hse-corporate-development.auth.eu-central-1.amazoncognito.com",
    clientId: "65lejg736f9u9qpbqevonp979a"
  },
};

const developmentConfiguration: Configuration = {
  env: Environment.Development,
  baseUrl: "https://hse-corporate.core.hse24-development.cloud",
  cognito: {
    url: "https://hse-corporate-development.auth.eu-central-1.amazoncognito.com",
    clientId: "65lejg736f9u9qpbqevonp979a"
  },
};

const stagingConfiguration: Configuration = {
  env: Environment.Staging,
  baseUrl: "https://hse-corporate.core.hse24-staging.cloud",
  cognito: {
    url: "https://hse-corporate-staging.auth.eu-central-1.amazoncognito.com",
    clientId: "6a4q2npl8s85533re2tu0nvbc2"
  },
};

const productionConfiguration: Configuration = {
  env: Environment.Production,
  baseUrl: "https://corporate.hse.com",
  cognito: {
    url: "https://hse-corporate-production.auth.eu-central-1.amazoncognito.com",
    clientId: "496u63hnc521qedi04bfr47m38"
  },
};

const getConfiguration = (): Configuration => {
  const env = getEnvironment();

  switch (env) {
    case Environment.Local:
      return localConfiguration;
    case Environment.Development:
      return developmentConfiguration;
    case Environment.Staging:
      return stagingConfiguration;
    case Environment.Production:
      return productionConfiguration;
    default:
      throw Error(`Unknown environment: ${env}`);
  }
};

const Config = new Proxy<Configuration>(getConfiguration(), {
  get: (target: object, propertyKey: PropertyKey, receiver?: any) => {
    return Reflect.get(getConfiguration(), propertyKey, receiver);
  }
});

export default Config;
